// src/routes/FindWisdomRoutes.tsx
import React, {Suspense} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {useAuth} from '../context/AuthContext';
import ProtectedRoute from '../components/routes/ProtectedRoute';
import PublicRoute from '../components/routes/PublicRoute';
import LoginPage from '../components/auth/LoginPage';
import RegisterPage from '../components/auth/RegisterPage';
import AdminDashboard from '../components/admin/AdminDashboard';
import AuthorDashboard from '../components/authorEdit/AuthorDashboard';
import AuthorsGallery from '../components/AuthorsGallery';
import StealthComponent from '../components/Stealth';
import AuthorPage from '../components/authorPage/AuthorPage';
import PublicClosed from '../components/auth/PublicClosed';
import {logComponentLoad} from '../utils/debugLogger';
import Loading from '../components/loader/Loading';
import ProfileSectionFull from '../components/authorPage/ProfileSectionFull';
import AuthorProviderWrapper from "../context/AuthorProviderWrapper";

// Our new wrapper

const FindWisdomRoutes: React.FC = () => {
    const {user, loading} = useAuth();
    logComponentLoad('FindWisdomRoutes', {user, loading});

    // Decide what to show at "/" depending on user type
    const HomeRedirect = () => {
        if (!user) return <RegisterPage/>;
        if (user?.userType === 'author') return <Navigate to={`/author/${user.authorId}/chat`}/>;
        if (user?.userType === 'admin') return <AuthorsGallery/>;

        const allowedAuthors = user?.allowedAuthors || [];
        if (allowedAuthors.length > 0) {
            const defaultAuthorId = allowedAuthors[0];
            return <Navigate to={`/author/${defaultAuthorId}/chat`} />;
        }

        return <PublicClosed />;
    };

    return (
        <Suspense fallback={<Loading type="spinner" message="Loading..."/>}>
            <Routes>
                {/* Login / Register remain public */}
                <Route
                    path="/login"
                    element={<PublicRoute element={<LoginPage/>} redirectTo="/"/>}
                />
                <Route
                    path="/register"
                    element={<PublicRoute element={<RegisterPage/>} redirectTo="/"/>}
                />

                {/* Admin sub-routes */}
                <Route
                    path="/admin/*"
                    element={user?.userType === 'admin' ? <AdminDashboard/> : <Navigate to="/"/>}
                />
                <Route path="/author/:authorId" element={<AuthorProviderWrapper/>}>
                    <Route
                        path="chat"
                        element={<ProtectedRoute element={<AuthorPage/>}/>}
                    />
                    <Route
                        path="settings/*"
                        element={
                            user?.userType === 'author' || user?.userType === 'admin'
                                ? <AuthorDashboard/>
                                : <Navigate to="/"/>
                        }
                    />
                    <Route
                        path="profile"
                        element={<ProfileSectionFull/>}
                    />
                </Route>

                <Route path="/" element={<HomeRedirect/>}/>

                <Route path="*" element={user ? <Navigate to="/"/> : <RegisterPage/>}/>
            </Routes>
        </Suspense>
    );
};

export default FindWisdomRoutes;
