import React, { useEffect, useState } from 'react';
import styles from './AuthorList.module.css';
import { useNavigate } from 'react-router-dom';
import authorService from "../../services/authorService";
import { toast } from "react-toastify";
import { logComponentLoad } from "../../utils/debugLogger";
import { MdOutlineDrafts, MdCheckCircle, MdCreate } from 'react-icons/md';
import { Author } from "../../interfaces/interfaces";
import { useAuth } from '../../context/AuthContext'; // <-- Import the auth hook

const AuthorList: React.FC = () => {
    logComponentLoad('AuthorList');
    const [authors, setAuthors] = useState<Author[]>([]);
    const [showPublishedOnly, setShowPublishedOnly] = useState(false); // New state for filtering
    const navigate = useNavigate();
    const { user } = useAuth(); // Get the current user

    useEffect(() => {
        const fetchAuthors = async () => {
            try {
                const data = await authorService.getAuthors();
                setAuthors(data);
            } catch (error) {
                console.error('Error fetching authors:', error);
            }
        };

        fetchAuthors();
    }, []);

    const handleAuthorClick = (author: Author) => {
        const authorId = author.id;
        if (author.books && author.books.length > 0) {
            const bookId = author.books[0].id;
            navigate(`/author/${authorId}/chat/`);
        } else {
            navigate(`/author/${authorId}/settings`);
            toast.error('This author has no books available.');
        }
    };

    // If the filter is enabled, only display published authors
    const filteredAuthors = showPublishedOnly
        ? authors.filter(author => author.status === 'published')
        : authors;

    return (
        <div className={styles.authorsContainer}>
            {user?.userType === 'admin' && (
                <div className={styles.filterContainer}>
                    <label className={styles.filterLabel}>
                        <input
                            type="checkbox"
                            checked={showPublishedOnly}
                            onChange={() => setShowPublishedOnly(prev => !prev)}
                            className={styles.filterCheckbox}
                        />
                        Show Published Only
                    </label>
                </div>
            )}

            {filteredAuthors.map((author) => (
                <div
                    key={author.id}
                    className={styles.authorCard}
                    onClick={() => handleAuthorClick(author)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            handleAuthorClick(author);
                        }
                    }}
                >
                    <div className={`${styles.statusIcon} ${author.status === 'draft' ? styles.draft : styles.published}`}>
                        {author.status === 'draft' ? <MdCreate /> : <MdCheckCircle />}
                    </div>
                    <img
                        src={author.photo || require("./../../assets/pictures/author-default-avatar.png")}
                        alt={author.name}
                        className={styles.authorImage}
                    />
                    <div className={styles.authorName}>{author.name}</div>
                    {author.title && (
                        <div className={styles.authorSubtitle}>{author.title}</div>
                    )}
                    {author.location && (
                        <div className={styles.authorLocation}>{author.location}</div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default AuthorList;
