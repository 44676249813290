import axios from 'axios';
import { format, subDays } from 'date-fns';
import { User, Author } from '../interfaces/interfaces';

const API_URL = process.env.REACT_APP_API_URL;

const getToken = () => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No token found');
    return token;
};

const config = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});

const getLast30Days = () => {
    const today = new Date();
    return Array.from({ length: 30 }, (_, i) => format(subDays(today, 29 - i), 'yyyy-MM-dd'));
};

const mergeDataWithLast30Days = (data: { date: string; count: number }[]) => {
    const last30Days = getLast30Days();
    const dataMap = new Map(data.map((item) => [item.date, item.count]));
    return last30Days.map((date) => ({
        date,
        count: dataMap.get(date) || 0,
    }));
};

// Stats Methods
const getUserStats = async () => {
    const response = await axios.get(`${API_URL}/admin/users/count`, config());
    const mergedData = mergeDataWithLast30Days(response.data.usersPerDay);
    return { totalUsers: response.data.totalUsers, usersPerDay: mergedData };
};

const getAuthorStats = async () => {
    const response = await axios.get(`${API_URL}/admin/authors/count`, config());
    const mergedData = mergeDataWithLast30Days(response.data.authorsPerDay);
    return { totalAuthors: response.data.totalAuthors, authorsPerDay: mergedData };
};

const getBookStats = async () => {
    const response = await axios.get(`${API_URL}/admin/books/count`, config());
    const mergedData = mergeDataWithLast30Days(response.data.booksPerDay);
    return { totalBooks: response.data.totalBooks, booksPerDay: mergedData };
};

//Users method
const getUsers = async (page: number = 1, pageSize: number = 10, search: string = '') => {
    const query = `?page=${page}&pageSize=${pageSize}${search ? `&search=${search}` : ''}`;
    const response = await axios.get(`${API_URL}/admin/users${query}`, config());
    return response.data; // { users: User[], totalCount: number }
};

const createUser = async (user: Partial<User>) => {
    const response = await axios.post(`${API_URL}/admin/users`, user, {
        ...config(),
        headers: {
            ...config().headers,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

const updateUser = async (userId: string, user: Partial<User>) => {
    const response = await axios.put(`${API_URL}/admin/users/${userId}`, user, {
        ...config(),
        headers: {
            ...config().headers,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

const deleteUser = async (userId: string) => {
    const response = await axios.delete(`${API_URL}/admin/users/${userId}`, config());
    return response.data;
};

//Authors area
const getAuthors = async (page: number = 1, pageSize: number = 10, search: string = '') => {
    const query = `?page=${page}&pageSize=${pageSize}${search ? `&search=${search}` : ''}`;
    const response = await axios.get(`${API_URL}/admin/authors${query}`, config());
    return response.data; // { authors: Author[], totalCount: number }
};

const getAuthorsWithUsers = async (page: number = 1, pageSize: number = 10, search: string = '') => {
    const query = `?fetchUsers=true&page=${page}&pageSize=${pageSize}${search ? `&search=${search}` : ''}`;
    const response = await axios.get(`${API_URL}/admin/authors${query}`, config());
    return response.data; // { authors: Author[], totalCount: number }
};

const createAuthor = async (author: Partial<Author>) => {
    const response = await axios.post(`${API_URL}/admin/authors`, author, {
        ...config(),
        headers: {
            ...config().headers,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

const updateAuthor = async (authorId: string, author: Partial<Author>) => {
    const response = await axios.put(`${API_URL}/admin/authors/${authorId}`, author, {
        ...config(),
        headers: {
            ...config().headers,
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

const deleteAuthor = async (authorId: string) => {
    const response = await axios.delete(`${API_URL}/admin/authors/${authorId}`, config());
    return response.data;
};

// Book Methods
const getBooks = async (page: number = 1, pageSize: number = 10) => {
    const response = await axios.get(`${API_URL}/admin/books?page=${page}&pageSize=${pageSize}`, config());
    return response.data; // { books: Book[], totalCount: number }
};

// Book Methods
const getBooksWithAuthor = async (page: number = 1, pageSize: number = 10) => {
    const response = await axios.get(`${API_URL}/admin/books?withAuthor=true&page=${page}&pageSize=${pageSize}`, config());
    return response.data; // { books: Book[], totalCount: number }
};

const indexBook = async (authorId:string, formData: FormData) => {
    const response = await axios.post(`${API_URL}/admin/authors/${authorId}/book`, formData, {
        ...config(),
        headers: {
            ...config().headers,
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
};

const updateBook = async (authorId: string, bookId: string, formData: FormData) => {
    const response = await axios.put(`${API_URL}/admin/authors/${authorId}/books/${bookId}`, formData, config());
    return response.data;
};

const deleteBook = async (bookId: string) => {
    const response = await axios.delete(`${API_URL}/admin/books/${bookId}`, config());
    return response.data;
}

const publishAuthor = async (authorId: string) => {
    const response = await axios.put(`${API_URL}/admin/authors/${authorId}/publish`, {}, config());
    return response.data;
};

const unpublishAuthor = async (authorId: string) => {
    const response = await axios.put(`${API_URL}/admin/authors/${authorId}/unpublish`, {}, config());
    return response.data;
};

const adminService = {
    getUserStats,
    getAuthorStats,
    getBookStats,
    getUsers,
    createUser,
    updateUser,
    deleteUser,
    getAuthors,
    getAuthorsWithUsers,
    createAuthor,
    updateAuthor,
    deleteAuthor,
    getBooks,
    getBooksWithAuthor,
    deleteBook,
    indexBook,
    updateBook,
    publishAuthor,
    unpublishAuthor,
};

export default adminService;
